<!--
 * @Description: 绿链行动
 * @Author: wangying
 * @Date: 2021-09-29 09:51:40
-->
<template>
  <div>
    <div class="gsc">
      <img src="@/assets/gsg.png" alt="">
      <div class="title">
        <div class="flex">
          <div>
            <div>
              <span class="textNum Impact">100</span>
              <span class="textby" v-if="$store.state.language === 'cn'">多家</span>
              <span class="textby" v-else>+</span>
            </div>
            <div class="titleb"  v-if="$store.state.language === 'cn'">
              房地产企业
            </div>
            <div class="titleb" v-else>
              real estate enterprises
            </div>
          </div>
          <div>
            <div>
              <span class="textNum Impact">19</span>
              <span class="textby" v-if="$store.state.language === 'cn'">个</span>
            </div>
            <div class="titleb" v-if="$store.state.language === 'cn'">
              绿名单品类
            </div>
            <div class="titleb" v-else>
              green list categories
            </div>
          </div>
          <div>
            <div>
              <span class="textNum Impact">19</span>
              <span class="textby" v-if="$store.state.language === 'cn'">个</span>
            </div>
            <div class="titleb" v-if="$store.state.language === 'cn'">
              白名单品类
            </div>
            <div class="titleb" v-else>
              white list categories
            </div>
          </div>
        </div>
        <div class="flex bmt">
          <div>
            <div>
              <span class="textNum Impact">80</span>
              <span class="textby"  v-if="$store.state.language === 'cn'">家</span>
              <span class="textby"  v-else>+</span>
            </div>
            <div class="titleb" v-if="$store.state.language === 'cn'">
              绿名单企业
            </div>
            <div class="titleb" v-else>
              green list enterprises
            </div>
          </div>
          <div>
            <div>
              <span class="textNum Impact" v-if="$store.state.language === 'cn'">超100万</span>
              <span class="textNum Impact" v-else>1 million</span>
              <span class="textby" v-if="$store.state.language === 'cn'">家</span>
              <span class="textby" v-else>+</span>
            </div>
            <div class="titleb" v-if="$store.state.language === 'cn'">
              白名单企业
            </div>
            <div class="titleb" v-else>
              white list enterprises
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contentsy">
      <div v-html="content"></div>
    </div>
  </div>
</template>
<script>
import { articleDetail } from '@/api'
import '@/css/content.less'


export default {
  data() {
    return {
      content: null
    }
  },
  mounted() {
    this.hub.$emit('tatleNames')
    this.hub.$emit('theCover', this.$route.meta.flagNam)

    this.hub.$emit('scrollToz')
    const params = {
      articleId: 'WZNR201610311925050031',
      isEn: this.$store.state.language === 'en' ? 1 : 0
    }
    articleDetail(params).then((res) => {
      console.log(res.data);
      this.content = res.data.content

    })
  }
}
</script>
<style lang="less" scoped>
.gsc {
  .flex {
    display: flex;
    justify-content: space-between;
  }

  position: relative;

  text-align: center;

  // height: 550px;
  // padding: 80px;
  img {
    margin: 80px 0 40px 0;
    width: 684px;
    // height: 500px;
    // position: absolute;
    // left: 50%;
    // top: 50;
    // transform: translate(-50%);
  }

  .title {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 80px;

    .textNum {
      font-size: 90px;
      color: #92ab26;
    }

    .textby {
      float: right;
      font-size: 20px;
      margin-top: 20px;
      margin-left: 6px;
      color: #92ab26;
    }

    .titleb {
      margin-top: -10px;
      font-size: 18px;
    }

    .bmt {
      margin-top: 90px;
      justify-content: space-around;
    }
  }
}
</style>